import gql from 'graphql-tag';

const nominasTableQuery = gql`
    query nominas($numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        nominas(first: $numberItems, page: $numberPage, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                periodo_id,
                periodo{
                    numero,
                    fecha_de_pago
                }
                tipo_nomina_id,
                tipoNomina{
                    clave,
                    cliente_id,
                }
                incidencias_checador,
                incidencias_capturadas,
                modificaciones_adicionales,
                modificacionAdicional {
                    id,
                    empresa_id,
                    comision,
                    iva,
                    aplica_retencion_iva,
                    aplica_retencion_isr
                }
                detalles{
                    empleado_id,
                },
                generado,
                timbrada
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { nominasTableQuery };

export default queries;
